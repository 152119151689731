import {
  SELECT_INPUT, TEXT_INPUT, SELECT_CHILD_INPUT, CHECKBOX_LEF,
} from '@/views/components/DynamicForm/constants'

export default function config() {
  const ACCESS_ABILITY_FOR_SYSTEM_ORGANIZATION = { action: 'change', subject: 'Setup Organization' }

  const organizationFields = {
    org_name: {
      type: TEXT_INPUT,
      label: 'Company Name / DBA',
      placeholder: 'Type...',
      rules: 'required',
    },
    time_zone_id: {
      type: SELECT_INPUT,
      store: 'timezoneList',
      label: 'Time Zone',
      options: {
        label: 'timezone',
      },
      rules: 'required',
      placeholder: 'Select...',
    },
    primary_currency_id: {
      type: SELECT_INPUT,
      store: 'currencyList',
      rules: 'required',
      label: 'Currency',
      options: {
        label: 'iso_currency',
      },
      placeholder: 'Select...',
    },
    def_date_format: {
      type: SELECT_INPUT,
      store: 'dateformatList',
      label: 'Date Format',
      rules: 'required',
      options: {
        label: 'label',
      },
      placeholder: 'Select...',
    },
    def_measures: {
      type: SELECT_INPUT,
      store: 'measurementCategoriesList',
      label: 'Measurement Standard',
      rules: 'required',
      placeholder: 'Select...',
    },
    def_iso_lang: {
      type: SELECT_INPUT,
      store: 'languageList',
      label: 'Default User Language',
      rules: '',
      options: {
        label: 'iso_name',
      },
      placeholder: 'Select...',
    },
    export_file_enc: {
      type: SELECT_INPUT,
      store: 'exportFileEncodingList',
      label: 'Export File Encoding',
      rules: 'required',
      placeholder: 'Select...',
    },
    requires_edit_confirmation: {
      type: CHECKBOX_LEF,
      label: 'Active',
      rules: 'required',
    },
    is_active: {
      type: CHECKBOX_LEF,
      label: 'Active',
      rules: 'required',
    },
  }

  const detailFields = {
    org_address: {
      type: TEXT_INPUT,
      label: 'Street Address',
      rules: 'required',
      placeholder: 'Type...',
    },
    org_city: {
      type: TEXT_INPUT,
      label: 'City',
      rules: 'required',
      placeholder: 'Type...',
    },
    org_country_id: {
      type: SELECT_INPUT,
      store: 'countryList',
      label: 'Country',
      rules: 'required',
      options: {
        selectable: country => country.id === 1,
      },
      placeholder: 'Select...',
    },
    org_state_id: {
      type: SELECT_CHILD_INPUT,
      label: 'State',
      rules: 'required',
      store: 'stateList',
      placeholder: 'Select...',
    },
    org_zipcode: {
      type: TEXT_INPUT,
      label: 'Postal Code',
      rules: 'required',
      placeholder: 'Type...',
    },
    org_phone: {
      type: TEXT_INPUT,
      label: 'Main Phone',
      rules: 'numeric|min:7|max:15',
      placeholder: 'Type...',
    },
    org_phone2: {
      type: TEXT_INPUT,
      label: 'Alternate Phone Number',
      rules: 'numeric|min:7|max:15',
      placeholder: 'Type...',
    },
    org_fax: {
      type: TEXT_INPUT,
      label: 'FAX',
      rules: '',
      placeholder: 'Type...',
    },
    duns: {
      type: TEXT_INPUT,
      label: 'DUNS number',
      rules: '',
      placeholder: 'Type...',
    },
    send_emails_from: {
      type: SELECT_INPUT,
      store: 'sendEmailFromList',
      label: 'Send E-mails from',
      rules: 'required',
      placeholder: 'Select...',
    },
    add_email_to_cc: {
      type: CHECKBOX_LEF,
      label: 'Add an email to CC',
      rules: '',
    },
    global_bcc_address: {
      type: TEXT_INPUT,
      label: 'Global BCC address',
      rules: 'email',
      placeholder: 'Type...',
    },
    read_receipt_email: {
      type: CHECKBOX_LEF,
      label: 'Read receipt for email is',
      rules: '',
    },
    reply_to_email: {
      type: TEXT_INPUT,
      label: 'Reply to email',
      rules: '',
      placeholder: 'Type...',
    },
    admin_email: {
      type: TEXT_INPUT,
      label: 'Admin email',
      rules: '',
      placeholder: 'Type...',
    },
    user_department_email: {
      type: TEXT_INPUT,
      label: 'User department email',
      rules: '',
      placeholder: 'Type...',
    },
    order_department_email: {
      type: TEXT_INPUT,
      label: 'Order department email',
      rules: '',
      placeholder: 'Type...',
    },
    support_department_email: {
      type: TEXT_INPUT,
      label: 'Support department email',
      rules: '',
      placeholder: 'Type...',
    },
    system_behavior_defaults: {
      type: TEXT_INPUT,
      label: 'Primary theme color ',
      rules: '',
      placeholder: 'Type...',
    },
    // edit_requires_to_click_an_edit_button: {
    //   type: CHECKBOX,
    //   label: 'Active',
    //   rules: '',
    // },
  }
  return {
    detailFields,
    organizationFields,
    ACCESS_ABILITY_FOR_SYSTEM_ORGANIZATION,
  }
}
