<template>
  <div>
    <b-row>
      <b-col>
        <div class="d-flex justify-content-end">
          <feather-icon
            v-if="organizationRequiresEditConfirmation"
            v-b-tooltip.noninteractive.hover.bottom
            :title="$t('Edit')"
            :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
            size="36"
            class="cursor-pointer"
            @click="isEditAble()"
          />
        </div>
      </b-col>
    </b-row>
    <p class="text-left font-weight-bolder font-medium-5">
      {{ $t('Organization Details') }}
    </p>
    <div>
      <error-alert
        :fields="organizationFields"
        :error="error"
      />
      <skeleton v-if="isLoading" />
      <div v-else>
        <b-form>
          <validation-observer
            ref="organizationUpdateVal"
          >
            <b-row>
              <b-col
                v-for="field in ['org_name', 'time_zone_id', 'primary_currency_id', 'def_date_format', 'def_iso_lang', 'def_measures', ]"
                :key="field"
                md="6"
              >
                <component
                  :is="organizationFields[field].type"
                  v-model="organization[field]"
                  :field="organizationFields[field]"
                  :name="field"
                  :is-editable="!$can(ACCESS_ABILITY_FOR_SYSTEM_ORGANIZATION.action,ACCESS_ABILITY_FOR_SYSTEM_ORGANIZATION.subject) || isEdit"
                />
              </b-col>
              <b-col
                v-for="field in ['org_address', 'org_city', 'org_country_id', 'org_state_id', 'org_zipcode', 'org_phone', 'org_phone2', 'org_fax',]"
                :key="field"
                md="6"
              >
                <!--                removed from view: 'duns', 'send_emails_from', 'reply_to_email', 'admin_email', 'user_department_email', 'order_department_email'-->
                <component
                  :is="detailFields[field].type"
                  v-model="detail[field]"
                  :field="detailFields[field]"
                  :name="field"
                  v-bind="getProps(field)"
                  :is-editable="!$can(ACCESS_ABILITY_FOR_SYSTEM_ORGANIZATION.action,ACCESS_ABILITY_FOR_SYSTEM_ORGANIZATION.subject) || isEdit"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-for="field in ['export_file_enc']"
                :key="field"
                md="6"
              >
                <component
                  :is="organizationFields[field].type"
                  v-model="organization[field]"
                  :field="organizationFields[field]"
                  :name="field"
                  :is-editable="!$can(ACCESS_ABILITY_FOR_SYSTEM_ORGANIZATION.action,ACCESS_ABILITY_FOR_SYSTEM_ORGANIZATION.subject) || isEdit"
                />
              </b-col>
              <b-col md="6" />
            </b-row>
            <p class="text-left font-weight-bolder font-medium-5 mt-1">
              {{ $t('System Behavior Defaults') }}
            </p>
            <b-row>
              <b-col
                v-for="field in ['system_behavior_defaults']"
                :key="field"
                md="6"
              >
                <component
                  :is="detailFields[field].type"
                  v-model="detail[field]"
                  :field="detailFields[field]"
                  :name="field"
                  v-bind="getProps(field)"
                  :is-editable="!$can(ACCESS_ABILITY_FOR_SYSTEM_ORGANIZATION.action,ACCESS_ABILITY_FOR_SYSTEM_ORGANIZATION.subject) || isEdit"
                />
              </b-col>
              <b-col md="6">
                <p class="text-left font-weight-bolder pl-1">
                  {{ $t('Editing requires to click an edit button') }}
                </p>
                <b-col
                  v-for="field in ['requires_edit_confirmation']"
                  :key="field"
                  md="12"
                  class="pl-0"
                >
                  <component
                    :is="organizationFields[field].type"
                    v-model="organization[field]"
                    :field="organizationFields[field]"
                    :name="field"
                    v-bind="getProps(field)"
                    :is-editable="!$can(ACCESS_ABILITY_FOR_SYSTEM_ORGANIZATION.action,ACCESS_ABILITY_FOR_SYSTEM_ORGANIZATION.subject) || isEdit"
                  />
                </b-col>
              </b-col>
            </b-row>
            <portal to="body-footer">
              <div class="d-flex mt-2 pb-1 justify-content-between">
                <div>
                  <b-button
                    v-if="!isFormChanged"
                    class="cancelBtn font-weight-bolder"
                    variant="outline-primary"
                    @click="$router.push({ name: 'home' }); "
                  >
                    {{ $t('Back to Home') }}
                  </b-button>
                  <b-button
                    v-else
                    class="cancelBtn font-weight-bolder"
                    variant="outline-primary"
                    @click="showConfirmationModalCancel"
                  >
                    <feather-icon
                      icon="LCancelIcon"
                      size="16"
                    />
                    {{ $t('Cancel') }}
                  </b-button>
                </div>
                <div>
                  <b-button
                    v-if="$can(ACCESS_ABILITY_FOR_SYSTEM_ORGANIZATION.action,ACCESS_ABILITY_FOR_SYSTEM_ORGANIZATION.subject) || isEdit"
                    variant="success"
                    class="saveBtn font-weight-bolder"
                    :disabled="!isFormChanged"
                    @click="submit"
                  >
                    {{ $t('SAVE') }}
                  </b-button>
                </div>
              </div>
            </portal>
          </validation-observer>
        </b-form>
      </div>
    </div>
    <b-s-warning-modal ref="confirm-modal" />
    <b-modal
      id="bs-warning-modal-cancel"
      ref="bs-warning-modal-cancel"
      centered
      hide-header
      body-class="bs-warning-modal__body px-2 py-2"
      footer-class="bs-warning-modal__footer justify-content-between"
      modal-class="bs-warning-modal"
    >
      <div class="text-center py-2">
        <feather-icon
          icon="LWarningIcon"
          size="44"
          class="mb-2"
        />
        <h4 class="font-weight-bolder">
          {{ $t('Changes will not be saved, are you sure to discard changes?') }}
        </h4>
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          size="md"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          size="md"
          variant="success"
          @click="clear"
        >
          Yes
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>

import { required, email } from '@/libs/validations/validations'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { ValidationObserver } from 'vee-validate'
import {
  BForm, BRow, BCol, BButton, VBTooltip,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { scrollToError } from '@core/utils/utils'
import jwtDefaultConfig from '@/auth/jwt/jwtDefaultConfig'
import store from '@/store'
import config from '../organizationConfig'
import Skeleton from '../components/Skeleton.vue'

export default {
  name: 'Organization',
  components: {
    ErrorAlert,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BForm,
    Skeleton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      required,
      email,
      error: {},
      detail: {},
      detailClone: {},
      organization: {},
      organizationClone: {},
      isLoading: false,
      onSubmit: false,
      // edit_requires_to_click_an_edit_button: false,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    isFormChanged() {
      return this.isDetailChanged || this.isOrganizationChanged
    },
    isDetailChanged() {
      return JSON.stringify(this.detailClone) !== JSON.stringify(this.detail)
    },
    isOrganizationChanged() {
      return JSON.stringify(this.organizationClone) !== JSON.stringify(this.organization)
    },
    // storedEditRequiresClick() {
    //   return localStorage.getItem('edit_requires_to_click_an_edit_button') === 'true'
    // },
  },
  created() {
    this.load()
    // this.edit_requires_to_click_an_edit_button = this.storedEditRequiresClick
  },
  beforeRouteLeave(to, from, next) {
    this.showConfirmationModal(next)
  },
  methods: {
    isEditAble() {
      this.isEdit = !this.isEdit
    },
    load() {
      this.isLoading = true
      this.organization = {}
      this.organizationClone = {}
      this.detailClone = {}
      this.$store.dispatch(`${this.MODULE_NAME}/get`).then(res => {
        const { data } = res.data
        const organization = this.mappingFields(Object.keys(this.organizationFields), data, {
          primary_currency_id: data.currency,
          def_date_format: data.date_format,
          def_measures: data.measurement,
          time_zone_id: data.time_zone,
          def_iso_lang: data.language,
          requires_edit_confirmation: data.requires_edit_confirmation,
          export_file_enc: this.$store.getters['listModule/exportFileEncodingFrom'](data.export_file_enc),
        })
        const detailData = data.detail
        const detail = this.mappingFields(Object.keys(this.detailFields), detailData, {
          org_country_id: detailData.country,
          // edit_requires_to_click_an_edit_button: this.edit_requires_to_click_an_edit_button,
          // send_emails_from: this.$store.getters['listModule/getSendEmailsFrom'](detailData.send_emails_from),
          org_state_id: detailData.state,
        })
        this.organization = organization
        this.organizationClone = { ...organization }
        this.detail = detail
        this.detailClone = { ...detail }
      }).finally(() => {
        this.isLoading = false
      })
    },
    submit() {
      this.error = {}
      this.onSubmit = true
      this.$refs.organizationUpdateVal.validate().then(success => {
        if (success) {
          const data = this.organization
          const organization = this.mappingFields(Object.keys(this.organizationFields), data, {
            primary_currency_id: data.primary_currency_id?.id,
            def_date_format: data.def_date_format?.id,
            def_measures: data.def_measures?.id,
            time_zone_id: data.time_zone_id?.id,
            def_iso_lang_id: data.def_iso_lang?.id,
            export_file_enc: data.export_file_enc?.id,
            requires_edit_confirmation: data.requires_edit_confirmation,
          })
          const detailData = this.detail
          const detail = this.mappingFields(Object.keys(this.detailFields), detailData, {
            org_country_id: detailData.org_country_id?.id,
            // send_emails_from: detailData.send_emails_from?.id,
            org_state_id: detailData.org_state_id?.id,
            // edit_requires_to_click_an_edit_button: this.edit_requires_to_click_an_edit_button,
          })
          this.sendNotification(this, { organization, detail }, `${this.MODULE_NAME}/update`)
            .then(() => {
              localStorage.setItem(jwtDefaultConfig.storageOrganizationRequiresEditConfirmationKeyName, organization.requires_edit_confirmation)
              localStorage.setItem(jwtDefaultConfig.storageOrganizationDateFormat, organization.def_date_format)
              localStorage.setItem('storageThemeColor', (detailData.system_behavior_defaults).replace(/"/g, ''))
              this.isEdit = organization.requires_edit_confirmation
              this.$store.commit('app/SET_ORGANIZATION_REQUIRES_EDIT_CONFIRMATION', organization.requires_edit_confirmation)
              // localStorage.setItem(this.jwtConfig.storageOrganizationRequiresEditConfirmationKeyName, JSON.parse(value).warehouse.organization.requires_edit_confirmation)
              if (this.detail.system_behavior_defaults !== this.detailClone.system_behavior_defaults) {
                // reload the page to get an update for icons on the header
                window.location.reload()
              }
              this.clear()
              this.$router.push({ name: 'settings-organization-update' }).catch(() => {})
            }).catch(err => {
              this.error = err.response?.data
            }).finally(() => {
              this.onSubmit = false
            })
        } else {
          scrollToError(this, this.$refs.organizationUpdateVal)
        }
      })
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    showConfirmationModalCancel() {
      this.$refs['bs-warning-modal-cancel'].show()
    },
    hideModal() {
      this.$refs['bs-warning-modal-cancel'].hide()
    },
    clear() {
      this.$refs.organizationUpdateVal.reset()
      this.$store.commit(`${this.MODULE_NAME}/GET`, {})
      this.load()
      this.hideModal()
    },
    // updateLocalStorage() {
    //   localStorage.setItem('edit_requires_to_click_an_edit_button', this.edit_requires_to_click_an_edit_button)
    // },
    getProps(fieldName) {
      const props = {}
      if (fieldName === 'org_state_id') {
        props.parentValue = this.detail.org_country_id?.id
      }
      return props
    },

    // changeEditable() {
    //   this.updateLocalStorage()
    // },

  },
  setup() {
    // console.log('muh',JSON.parse(localStorage.getItem('userData')).warehouse);
    // isEditPenAvailable: JSON.parse(localStorage.getItem('edit_requires_to_click_an_edit_button')) || false,
    const MODULE_NAME = 'organization'
    const { organizationFields, detailFields, ACCESS_ABILITY_FOR_SYSTEM_ORGANIZATION } = config()
    return {
      MODULE_NAME,
      detailFields,
      organizationFields,
      ACCESS_ABILITY_FOR_SYSTEM_ORGANIZATION,
    }
  },
}
</script>
