<template>
  <b-row>
    <b-col md="6">
      <div
        v-for="field in ['org_name', 'primary_currency_id', 'def_iso_lang', 'org_address', 'org_country_id', 'org_zipcode', 'org_phone2','export_file_enc']"
        :key="field"
        style="height: 70px; margin-bottom: 10px"
      >
        <span
          class="font-weight-bolder d-block"
          style="margin-bottom: 5px"
        >{{ fields[field].label }}</span>
        <b-skeleton
          height="40px"
          style="border-radius: 5px"
        />
      </div>

    </b-col>
    <b-col md="6">
      <div
        v-for="field in ['time_zone_id', 'def_date_format', 'def_measures', 'org_city', 'org_state_id', 'org_phone', 'org_fax']"
        :key="field"
        style="height: 70px; margin-bottom: 10px"
      >
        <span
          class="font-weight-bolder d-block"
          style="margin-bottom: 5px"
        >{{ fields[field].label }}</span>
        <b-skeleton
          height="40px"
          style="border-radius: 5px"
        />
      </div>
    </b-col>
    <b-col md="12">
      <p class="text-left font-weight-bolder font-medium-5">
        {{ $t('System Behavior Defaults') }}
      </p>
    </b-col>
    <b-col md="6">
      <div
        v-for="field in ['system_behavior_defaults']"
        :key="field"
        style="height: 70px; margin-bottom: 10px"
      >
        <span
          class="font-weight-bolder d-block"
          style="margin-bottom: 5px"
        >{{ fields[field].label }}</span>
        <b-skeleton
          height="40px"
          style="border-radius: 5px"
        />
      </div>
    </b-col>
    <b-col md="6">
      <p class="text-left font-weight-bolder">
        {{ $t('Editing requires to click an edit button') }}
      </p>
      <div
        v-for="field in ['requires_edit_confirmation']"
        :key="field"
        class="d-flex"
        style="margin-bottom: 10px"
      >
        <b-skeleton
          width="20px"
          height="20px"
          style="border-radius: 5px; margin: 0"
        />
        <span class="ml-1">{{ fields[field].label }}</span>
      </div>
    </b-col>

  </b-row>
</template>
<script>
import {
  BRow, BSkeleton, BCol,
} from 'bootstrap-vue'
import config from '../organizationConfig'

export default {
  name: 'Skeleton',
  components: {
    BCol,
    BRow,
    BSkeleton,
  },
  setup() {
    const { organizationFields, detailFields } = config()

    const fields = {
      ...organizationFields,
      ...detailFields,
    }

    return {
      fields,
    }
  },
}
</script>
